.btn-compare {
	margin-top: 50px !important;
}

.text-between-inputs {
	color: #212136;
	font-size: 14px;
	margin-bottom: 18px;
}

.result-key {
	color: #646885;
	position: relative;
	font-weight: bold;
	font-size: 14px;
}

.result-value {
	color: #32465c;
	margin-left: 10px;
	display: inline-block;
	font-size: 14px;
}

.result-title {
	margin-bottom: 14px;
	margin-top: 8px;
	font-size: 14px;
	font-weight: bold;
}

.result-data {
	margin-bottom: 1%;
}

.compare-top {
	border-bottom: none;
}

.compare-bottom {
	border-top: none;
}


.truncate {
	width: 700px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.compare-container {
	position: relative;
	display: block;
	top: 0px;
}

.is-loading::ng-deep .mat-option-text {
	display: flex;
	justify-content: center;
}

.DiagramLoader {
	width: 150px;
	margin-bottom: 30px;
	position: absolute;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 100000;
	display: block;
}

.DiagramLoaderTop {
	top: 20%;
}

.DiagramLoaderBottom {
	bottom: 20%;
}
