@import 'kendo/grid';

.k-grid .k-grid-content td {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.k-grid-header {
	.k-grid-filter {
		color: get-octopai-color(200);

		&:hover {
			background-color: inherit;
		}
	}
}

kendo-popup {
	.k-popup {
		//font-size: 0.7rem;
	}

	.k-tooltip {
		background: rgba(97, 97, 97, 0.9);
		padding-top: 6px;
		padding-bottom: 6px;
		border-radius: 5px;

		.k-callout {
			color: transparent;
		}
	}
}
