.form-modal {
	position: relative;
	height: 90vh;
	display: flex;
	flex-direction: column;

	.close-button {
		position: absolute;
		top: 10px;
		right: 15px;
		cursor: pointer;
	}

	.item-row {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}


	.add-new-form-body {
		flex: 1;
		padding: 0 40px;
		overflow: auto;
	}
}
