@import 'styles/octopai-themes';

.search-round-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 30px;
  box-shadow: 4px 4px 13px -8px #857c85;
  background: #FFFFFF 0 0 no-repeat padding-box;
  border: 1px solid get-octopai-color(400);
  border-radius: 21px;
  padding: 0 10px;

  .search {
    @include svg-octopai-color(200);
  }

  .search-input {
    background-color: transparent;
    border: none;
    color: get-octopai-color(200);
    width: 100%;
  }
}

#text-form-control {
  margin: 0.77rem 0.77rem 0.77rem 1.2rem;

  textarea, .input-label {
    color: get-octopai-color(200);
    font-size: 1.04rem;
    white-space: pre-line;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
  }
}

.new-text {
  border-radius: 10px;
  padding: 0 10px;
  height: 20px;
  width: 86px;
  border: 0.5px solid get-octopai-color(350);
  background-color: get-octopai-color(260);
}

.input-error {
  border: 2px solid #f4516c !important;
}

.center-container-input {
  display: flex;
  justify-content: center;
  align-self: center;
  width: 800px;

  .title {
    color: get-octopai-color(50) !important;
    font-size: 58px;
  }

  mat-icon {
    height: 95px;
    width: 80px;
    @include svg-octopai-color(600);
  }
}
