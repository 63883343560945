.flex {
	display: flex !important;
}

[flex-dircetion-rev] {
	flex-direction: row-reverse;
}

[flex-start] {
	justify-content: flex-start;
}

[flex-end] {
	justify-content: flex-end;
	align-items: flex-end;
}

[flex-center] {
	justify-content: center;
	align-items: center;
}

[flex-dircetion-col] {
	flex-direction: column;
}

[space-between] {
	justify-content: space-between;
}

[space-around] {
	justify-content: space-around;
}

[flex-align-items-center] {
	align-items: center;
}

[flex-align-start] {
	align-self: flex-start;
}

[flex-align-flex-end] {
	align-items: flex-end;
}
