@import "styles/octopai-themes";

table {
	&.custom-attributes-table {
		width: 100%;
		color: get-octopai-color(200);
		font-size: 14px;
		padding: 8px 12px;

		thead {
			position: fixed;
			z-index: 1;
			width: calc(100% - 128px);
			background-color: #f6f6f6;
			font-weight: 400;
		}

		tbody {
			position: relative;
			top: 35px;
			background-color: get-octopai-color(500);
		}

		th {
			text-align: left;
		}

		tr {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			border-bottom: solid 1px rgba(211, 211, 211, 0.7);

			.disabled {
				pointer-events: none;
				opacity: .3;
			}
		}
	}
}
