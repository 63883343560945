@import "octopai-themes";

m-portlet {
  .m-portlet {
    display: flex;
    flex-direction: column;

    .m-portlet {
      &__head {
        align-items: center;

        .main-header {
          width: 100%;
          justify-content: space-between;
          flex-direction: row;
          display: flex;
        }
      }
    }
  }

  &.posts {
    height: 100%;

    .m-portlet {
      border-top: 4px solid get-color(A550);
    }


    .m-portlet__head {
      padding: 0 10px;
    }

    .m-portlet__body {
      padding: 0;
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }

  &.linked-objects {
    .m-portlet {
      margin-bottom: 0 !important;
      border-top: 4px solid get-color(A550);
    }
  }

  //&.discovery {
  //  .m-portlet {
  //    margin: 0px 30px 30px 20px;
  //  }
  //}

  &.result-list-container {
    .m-portlet {
      margin-bottom: 0 !important;

      &__head {
        &--OBG {
			flex-direction: row-reverse;
			border-top: 4px solid get-color(A550);
			height: 65px;
        }
      }

      &__body {
        height: 100%;
      }
    }
  }

  &.linked-objects {
    .m-portlet {
      margin-bottom: 0 !important;
      border-top: 4px solid get-color(A550);

    }

    .main-header {
    }
  }

  &.object-properties {
    .m-portlet {
      margin-bottom: 0;
      border-top: 4px solid get-color(A550);

      &__head-tools {
        margin-top: 10px;
      }

      &__head {
        display: flex;
        flex-direction: column;
        height: auto;
        justify-content: flex-start;
        padding: 0;

        .main-header {
          height: 80px;
          align-items: flex-start;
          padding: 0 30px;
        }

        .sub-header {
          width: 100%;
			padding: 0 30px 7px;
          background-color: get-color(1000);
          border-top: 1px solid get-color(500);
          border-bottom: 1px solid get-color(500);

          ul {
            align-self: flex-start;
            list-style-type: none;
          }
        }

        &--OBG {
          flex-direction: column;
          height: auto;
          align-items: flex-start;

			.main-header {
				height: 50px;
			}
        }
      }
    }

    .m-portlet__body {
      &.bodyHeight {
        height: calc(100% - 280px);
      }
    }
  }
}

.m-portlet.m-portlet--rounded-force.height {
  height: 100%;
}

.m-portlet__body.bodyHeight {
  height: 89%;
}
