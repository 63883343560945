.CodeMirror, .CodeMirror-scroll {
	height: 100% !important;
	background-color: inherit !important;
	color: inherit !important;
}

//.CodeMirror-code {
//padding: 5px;
//}

.CodeMirror-scroll {
	background-color: #f7f7f7;
	border-radius: 4px;
}

.CodeMirror pre {
	box-shadow: none;
}

.code-snippet {
	background-color: #f5f0f0;
}

.cm-code-snippet-trigger {
	color: transparent !important;
}

.code-example {
	color: yellow !important;
}


.CodeMirror-selected {
	//background: yellow !important;
}

.code-mirror-marker {
	color: orange !important;
	font-weight: bold;
	border: solid 1px;
}

.search-marker {
	color: rgba(0, 191, 140, 0.6) !important;
	font-weight: 600;
	border: solid 1px;
}

oct-code-preview {
	&.map {
		height: 100%;

		.CodeMirror {
			height: 100% !important;
		}

		.code-preview {
			.search-wrapper {
				flex-wrap: wrap;
				width: auto;

				m-search-input {
					width: 250px;
				}
			}
		}

	}
}

m-list-properties {
	m-search-input {
		border-radius: 4px !important;
		margin-top: 4%;
	}

	.copyCodeContainer {
		width: inherit !important;
	}
}
