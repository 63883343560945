@import "styles/octopai-themes";

html {
	overflow: auto !important;
}

textarea {
	resize: none !important;
}

.cursor {
	cursor: pointer;
}

canvas {
	outline: none;
}

perfect-scrollbar {
	.ps-content {
		overflow: auto !important;
	}
}

// ***** Scroll Bar ****
::-webkit-scrollbar {
	width: 6px !important;
	height: 6px !important;
}

::-webkit-scrollbar-track-piece {
	//background-color: #ff8000 !important;
}

::-webkit-scrollbar-button {
	display: none;
	height: 13px;
	border-radius: 0;
	background-color: #ff8000;
}

::-webkit-scrollbar-button:hover {
	background-color: #ff6a00;
}

::-webkit-scrollbar-thumb {
	background-color: #b6b5b5;
	border-radius: 3px;
	border: 1px solid #939191;
}

::-webkit-scrollbar-thumb:hover {
	background-color: #ff6a00;
}

::-webkit-scrollbar-track {
	background-color: #efefef;
}

::-webkit-scrollbar-track:hover {
	background-color: #efefef;
}

// ***** Scroll Bar ****

.ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline-block;
}

.no-spaces {
	white-space: nowrap !important;
}

.overflow-hidden {
	overflow: hidden;
}

.overflow-auto {
	overflow: auto;
}

.height-present-3 {
  height: 3%;
}

.height-present-6 {
  height: 6%;
}

.width-present-4 {
  width: 4%;
}

.width-present-5 {
	width: 5%;
}

.width-present-10 {
  width: 10%;
}

.width-present-40 {
  width: 40%;
}

.width-present-60 {
  width: 60%;
}

.width-present-100 {
	width: 100%;
}
