.cdk-drag-preview {
	box-sizing: border-box;
	border-radius: 4px;
	box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
	0 8px 10px 1px rgba(0, 0, 0, 0.14),
	0 3px 14px 2px rgba(0, 0, 0, 0.12);
	display: table;
}

.cdk-drag-placeholder {
	background: rgba(177, 176, 176, 0.4);
	border: dotted 2px #999;
	min-height: 40px;
	transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-animating {
	transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
