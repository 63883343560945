.skeleton-container {
  display: flex;
  overflow: hidden;
  z-index: 1;

  .skeleton-object {
    height: 25%;
    margin-top: 2%;
    display: flex;
    margin-left: 4%;
  }
}
