@import 'styles/octopai-themes';

.mat-autocomplete-panel {
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
  top: 5px;
  padding: 5px;

  &.custom-autocomplete-panel {
    left: -10px;
  }

  &.user-autocomplete {
    .mat-option {
      padding: 0 0 0 5px;

      .mat-selected:not(.mat-active):not(:hover) {
        background: #fff
      }

      .mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
        color: rgba(0, 0, 0, 0.87)
      }

      .mat-option-text {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: dimgrey;
        font-weight: 600;
      }
    }
  }
}

.user-selector-wrapper {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-infix {
        display: flex !important;
      }
    }
  }
}

.mat-autocomplete-panel {
  &.new {
    background: get-octopai-color(500);
    padding: 0px;
    box-shadow: 0px 3px 6px get-octopai-color(800);
    border-radius: 10px;


    .mat-option {
      padding: 0 15px;
      font-size: 13px !important;
      height: 36px;

      .mat-selected:not(.mat-active):not(:hover) {
        background: #fff
      }

      .mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
        color: rgba(0, 0, 0, 0.87)
      }

      span.mat-option-text {
        display: flex;
        align-items: center;
        font-size: 13px !important;
        color: get-octopai-color(200);
      }
    }
  }
}

.custom-autocomplete-panel {
  left: 0;
  margin-top: 2px;
  border: 1px solid #E6EAEF;
  box-shadow: 0 3px 12px #869DB88C;
}
