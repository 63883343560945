mat-card {
  &.abg-filter {
    .mat-card-header {
    }

    .mat-card-title {
      margin-bottom: 0;
      font-size: 15px;
      font-weight: bold;
    }

    .filter-item-label {
      min-width: 100px;
    }

    .filter-item {
      min-height: 30px;
      margin: 5px 0;
      align-items: center;

      .mat-checkbox {
        margin: 0 10px;
      }

      .color-strip {
        width: 5px;
        height: 30px;
        margin-right: 10px;

        &.etl {
          background-color: var(--etl-color);
        }

        &.db {
          background-color: var(--db-color);
        }

        &.report {
          background-color: var(--report-color);
        }

        &.analysis {
          background-color: var(--analysis-color);
        }
      }
    }

    .check-box-group {
      font-weight: 800;
      min-height: 30px;

      &.etl {
        color: var(--etl-color);
      }

      &.db {
        color: var(--db-color);
      }

      &.report {
        color: var(--report-color);
      }

      &.analysis {
        color: var(--analysis-color);
      }
    }
  }
}
