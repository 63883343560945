ngx-daterangepicker-material {
	&.abg-date-range-picker {
		div {
			font-size: 10px;
			box-shadow: none !important;
		}

		.ranges {
			ul {
				li {
					border-radius: 20px;
					border: none;
					margin: 10px;
					height: 25px;


					button {
						border-radius: 20px;
						border: 1px solid #CCD5DF;
						width: 115px;
						height: 25px;
						display: flex;
						justify-content: center;
						align-items: center;
						color: #869db8;

						&.active {
							color: #F16642;
							border-color: #F16642;
							background-color: transparent;
						}

						&:hover:not(.active) {
							background-color: #F1664229;
						}
					}
				}
			}
		}

		.buttons_input {
			.btn {
				background-color: #E75052;

				&:hover {
					opacity: 0.5;
				}
			}
		}
	}

	tr {
		th {
			&.prev, &.next {
				color: #013463;
			}

			&.month {
				font-family: 'roboto-bold', Roboto, serif;
				color: #013463;
			}
		}

		&.week-days {
			th{
				font-family: 'roboto-medium', Roboto, serif;
				color: #013463 !important;
			}
		}
	}

	td {

		&.available {
			color: #869DB8;

		}

		&.start-date {
			border-radius: 9px 0px 0px 9px;
			background-color: #F16642 !important;
			color: white !important;
		}

		&.in-range {
			background-color: #F1664229 !important;
			color: #F7A38E !important;
		}

		&.end-date {
			background-color: #F16642 !important;
			color: white !important;
			border-radius: 0px 9px 9px 0px;
		}
	}


}
