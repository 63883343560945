@import "styles/octopai-themes";

:root {
  --icon-color-600: #F16642;
}


//#remove-custom-attr-btn {
//  svg {
//    margin-top: -10px !important;
//  }
//}

mat-icon {
  &.success {
    @include svg-octopai-color(100);
    //svg {
    //	fill: var(--green-btn) !important;
    //}
  }

	//cursor: default;
	&.search-icon {
		//width: 14px !important;
	}

	rect {
		fill: none;
	}

	svg {
		position: relative;

		path {
			opacity: 1;
		}
	}

	&.icon-20{
		width: 20px  !important;
	}

	&.icon-21{
		height: 19px;
		width: 19px;
	}

	&.small-icon {
		height: 15px !important;
	}

	&.super-small-icon {
		height: 11px !important;
	}

	&.item-list-icon {
		cursor: default !important;
		@include svg-octopai-color(50);
		height: 15px;
		width: 15px;
	}

	height: 100%;

	&.palette-50 {
		@include svg-octopai-color(50);
	}

	&.palette-250 {
		@include svg-octopai-color(250);
	}

	&.palette-600 {
		@include svg-octopai-color(600);
	}

	&.palette-320 {
		@include svg-octopai-color(320);
	}

	&.palette-1000 {
		@include svg-color(1000);
	}

	&.palette-A500 {
		@include svg-color(A500);
	}

	&.palette-100 {
		@include svg-color(100);
	}

	&.palette-500 {
		@include svg-octopai-color(500);
	}

	&.palette-1100 {
		@include svg-color(1100);
	}
}


::ng-deep mat-icon {
	&.adc-settings-icon {
		cursor: pointer;
		background-color: #F7F7F7FF;

		&.report {
			path.b {
				fill: #2eb67d !important;
			}
		}

		&.analysis {
			path.b {
				fill: #ecb433 !important;
			}
		}

		&.etl {
			path.b {
				fill: #36c5f0 !important;
			}
		}

		&.db {
			path.b {
				fill: #e01e5a !important;
			}
		}

		&.business {
			display: flex;
			justify-content: center;

			svg {
				width: 14px !important;
			}

			path.a {
				fill: var(--orange-bg);
			}
		}

		&.custom-attributes {
			display: flex;
			justify-content: center;

			svg {
				width: 14px !important;
			}

			path.a {
				fill: #F16642;
			}
		}

		&.disabled {
			g path {
				fill: #dfe1e5 !important;
			}

			path.a {
				fill: #dfe1e5 !important;
			}

			path.b {
				fill: #dfe1e5 !important;
			}
		}
	}
}


