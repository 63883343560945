as-split {

  &.abg-splitter {
    .as-split-gutter {
      background-color: #f2f3f8 !important;
    }
  }

  .as-split-gutter {
    height: 20px;

    .as-split-gutter-icon {
      transform: rotate(0deg);
      padding: 20px 5px;
      background-image: url("/assets/6dots-spliter.svg") !important;
    }
  }
}

.as-split-area {
  overflow: hidden !important;
}

.splitter {
  min-height: 70%;
  width: 0;
  border: 1px solid #DFE1E5;
  margin: 0 10px;
}

.file-uploader {
  .as-split-gutter-icon {
    transform: rotate(90deg) !important;
    width: auto !important;
  }
}

