@font-face {
  font-family: 'octopai';
  src: url('fonts/octopai.eot?x9uh5b');
  src: url('fonts/octopai.eot?x9uh5b#iefix') format('embedded-opentype'),
  url('fonts/octopai.ttf?x9uh5b') format('truetype'),
  url('fonts/octopai.woff?x9uh5b') format('woff'),
  url('fonts/octopai.svg?x9uh5b#octopai') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.octopai {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'octopai' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.octopai-arrow-up:before {
  content: "\ea3a";
}

.octopai-collapse-right:before {
  content: "\e906";
}
.octopai-adc-white:before {
  content: "\e908";
}

.octopai-lineage-menu-arrow-left:before {
  content: "\e904";
}

.octopai-lineage-menu-arrow-right:before {
  content: "\e903";
}

.octopai-cross-white:before {
  content: "\e902";
}

.octopai-inner-map-white:before {
  content: "\e905";
}

.octopai-i-white:before {
  content: "\e900";
}
.octopai-cross-system-white:before {
  content: "\e901";
}

.octopai-adc-white1:before {
  content: "\e909";
}

.octopai-arrow-down2:before {
  content: "\ea3e";
}
.octopai-eye-blocked:before {
  content: "\e90a";
}

.octopai-eye:before {
  content: "\e90b";
}

.octopai-Colleps-left:before {
  content: "\e907";
}
