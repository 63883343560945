.m-widget5 {
	&__item {
		margin-bottom: 0;
	}

	&__content {
		width: 100% !important;

		&__section {
		}
	}
}
