@import '../octopai-themes.scss';

kendo-grid {
	table {
		tbody {
			tr {
				width: 100vw;

				&.k-disabled {
					pointer-events: none;
					opacity: .5;
					background-color: rgba(0, 0, 0, 0.1) !important;
				}

				td {
					&.is-mark {
						background-color: #0a8cf0 !important;
					}
				}
			}
		}
	}
}

.k-grouping-row td, tbody .k-group-cell {
	background-color: #f2f3f8;
}

.grid-actions {
	button {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.grid-actions-icon {
		@include svg-color(50);
	}
}

.k-pager-wrap {
	background-color: get-octopai-color(500);
}

.k-grid-toolbar {
	background-color: get-octopai-color(500);
	border-bottom: none;

	oct-round-button {
		display: inline-flex;
	}
}

.k-grouping-header {
	background-color: #f2f3f8;
	padding-bottom: 1%;
	color: get-octopai-color(200);
}

tbody .k-hierarchy-cell, .k-detail-row {
	color: rgb(54, 91, 137);
	background-color: get-octopai-color(500) !important;
}

tr:hover {
	color: get-octopai-color(200) !important;
}

.toolbar-btns {
	display: flex;
	justify-content: space-between;
	width: 100%;

	.search-input-size {
		width: 30%;
	}

	.input-to-fat-btn {
		align-self: center;

		input[type="file"] {
			display: none;
		}

		.custom-file-upload {
			padding: 4px 16px;
			cursor: pointer;
			background-color: white;
			color: #365b89;
			border: 1px solid #ccd5df;
			border-radius: 15px;
			font-size: 1rem;
			font-family: 'roboto-medium', Roboto, serif;
			margin: 0;
			box-shadow: 0 3px 6px #ccd5df;
		}
	}
}

.tooltip-wrapper {
	align-self: center;
}

.gray-btn {
	background-color: var(--disable) !important;
	border-color: var(--disable) !important;
	color: var(--octopai) !important;
}

.k-button, .k-button:hover {
	border: none;
	background-color: get-octopai-color(500);
	background-image: none;
}

.k-action-button {
	background: none;
}

.grid-tooltip {
	word-break: break-all !important;
	white-space: normal !important;
}

.k-pager-numbers .k-state-selected, .k-link, .k-pager-wrap .k-pager-sizes > select {
	border-radius: 10px;
	padding-top: 5px;
	padding-bottom: 5px;
}

.k-reset {
	color: get-octopai-color(200);
	font-weight: 500;
}

.grid-as-list {
	td {
		border-color: transparent;
		border-bottom: 1px solid #00000014;
	}
}
