body .mat-tooltip {
  font-size: 14px;
}

.extend-tooltip {
  text-overflow: clip;
  word-break: break-all;
}


