@import 'styles/octopai-themes';

mat-chip-list {
  .mat-chip-list-wrapper {
    margin: 0;
  }

	&.trending-tags {
		.mat-chip-list-wrapper {
			overflow-y: auto;
			height: auto;
			max-height: calc(100% - 30px);
		}
	}
}


.mat-chip.mat-standard-chip {

	&.new {
		background-color: get-octopai-color(500);
		color: get-octopai-color(200);
		border: 1px solid get-octopai-color(350);
		border-radius: 30px;
		min-height: 20px;
		padding: 0;
		max-width: 240px;

		.mat-chip-remove {
			color: get-octopai-color(200);
			opacity: 1;
		}

		.mat-chip-trailing-icon {
			margin: 0px;
		}

		mat-icon {
			display: none;
			width: 17px;
			height: 17px;
		}
	}

}

.mat-chip.mat-standard-chip:hover {
	&.new {
		.mat-icon {
			display: flex;
		}
	}
}

.mat-standard-chip .mat-chip-remove, .mat-standard-chip .mat-chip-trailing-icon {
	&.new {
		margin: 0px;
	}
}
