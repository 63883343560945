@import '../octopai-themes';

.cdk-overlay-pane {
	max-width: 90vw !important;

	.mat-dialog-container {
		padding: 0;
	}
}

.mat-dialog-container {
	overflow: hidden !important;
	box-shadow: 0 0 12px get-octopai-color(800);
	border-radius: 1rem !important;

	.mat-dialog-title {
		font-size: 1.3em;
		width: 100%;
		height: 60px;
		color: #212136;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #DFE1E5;
		margin: 0;
		padding-left: 22px;

		h2 {
			display: flex;
		}

		.close-btn {
			display: flex;
			justify-content: center;
			align-items: center;
			padding-right: 30px;
		}
	}

	.mat-dialog-content {
		max-height: 600px;
		overflow: auto;
		padding: 20px 20px;
		margin: 0;
		border-bottom: 1px solid #DFE1E5;

		textarea {
			min-height: 100px;
		}
	}

	.mat-dialog-actions {
		display: flex;
		justify-content: flex-end;
		margin: 0 25px;
		height: 60px;
		padding: 0;

		.action-btn {
			width: 155px;
			height: 35px;
			background-color: get-color(50);

			&.mat-raised-button[disabled] {
				background-color: get-color(50);
				color: get-color(1000);
			}
		}

		.submit-btn[disabled] {
			background-color: get-color(500);
			color: get-color(1000);
		}
	}
}

.backdropBackground {
	filter: blur(20px);
	background-color: get-octopai-color(500);
	opacity: 0.8 !important;
}
