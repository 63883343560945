.mat-badge {
  &.img-badge {
    position: absolute;
    right: 15px;
    top: 10px;
  }

  &.badge-transparent {
    .mat-badge-content {
      font-size: 10px;
      background-color: transparent;
    }
  }
}
