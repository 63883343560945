@import "octopai-themes";


mention-list {
	ul.dropdown-menu.scrollable-menu.mention-menu {
		background: get-octopai-color(500);
		padding: 0px;
		box-shadow: 0px 3px 6px get-octopai-color(800);
		border-radius: 10px !important;

		&.mention-menu {
			background: #fff;
			max-width: 250px;

			&.scrollable-menu {
				overflow-x: hidden;
			}

			li {
				.mention-item {
					padding: 0 15px;
					font-size: 13px !important;
					height: 36px;
					display: flex;
					align-items: center;
					color: get-octopai-color(200);

				}

				&.mention-active a {

					font-size: 13px !important;
					background-color: #0000000a;
				}
			}
		}
	}
}
