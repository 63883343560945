@import "styles/octopai-themes";

mat-slide-toggle {
  position: relative;
  width: 100%;

  .mat-slide-toggle-label {
    margin: 0;
  }

  .mat-slide-toggle-bar {
    background-color: get-octopai-color(850);
    height: 24px;
    border-radius: 15px;
    width: 100%;
    margin: 0;
  }

  .mat-slide-toggle-content {
    position: absolute;
    height: 22px;
    color: get-octopai-color(500);
    border-radius: 20px;
    width: 100%;
    font-weight: 500;

    span {
      display: block;
      position: absolute;
    }

    .off {
      right: 3px;
    }

    .on {
      left: 3px;
    }
  }

  .mat-slide-toggle-thumb-container {
    top: 2px;
    left: 1px
  }

  .mat-slide-toggle-thumb {
    background-color: get-octopai-color(500) !important;
  }

  &.mat-checked {
    .mat-slide-toggle-bar {
      background-color: get-octopai-color(550) !important;
    }

    //.mat-slide-toggle-thumb-container {
    //  transform: translate3d(23px, 0, 0) !important;
    //}
  }
}
