@import '~@angular/material/theming';

@include mat-core();

// old ui
$octopai-default: (
        1: transparent,
        20: #00000000,
        50 : #E75052,
        60 : #FF6062,
        100 : #2eb67d,
        200 : #ecb433,
        300 : #e01e5a,
        400 : #36c5f0,
        500 : #dfe1e5, // Disabled (button)
        550 : #f7f3f0,
        570 : #ffffff,
        600 : #229e92,
        700 : #1c9588,
        800 : #178b7e,
        900 : #0d7b6c,
        1000: #FFFFFF,
        1100: #2C2E3E,
        A100 : #adfff3,
        A200 : #7affec,
        A400 : #575962,
        A500 : #838A92,
        A550 : #646885,
        A600 : #DFE1E5,
        A650 : #F2F3F8,
        A700 : #2dffe0,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #000000,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);

// new ui
$octopai: (
        1: transparent,
        50: #12253C,
        100: #19314E,
        200: #365B89,
        240: #838A92,
        250: #E6EAEF66,
        260: #e6eaefcc,
        300: #869DB8,
        320: #2eb67d,
        350: #CCD5DF,
        400: #E6EAEF,
        450: #F5F7F9,
        500: #FFFFFF,
        550: #E43939,
        600: #F16642, // octopai orange color
        700: #F7A38E,
        750: #000000de,
        800: #869DB88C, //box shadow color 1
        850: #869DB84C, //box shadow color 2
        860: #365B8924, //box shadow color 3
        870: #365B8933, //box shadow color 4
        A100: #DC3565,
        A200: #36C3DD,
        A300: #2699FB,
        A400: #65DC7E,
        A500: #F1AB05,
        contrast: (
                50: rgba(black, 0.87),
                100: rgba(black, 0.87),
                200: rgba(black, 0.87),
                300: white,
        )
);

@function getColorByType($type) {
  @if ($type == 'report' or $type == 'REPORT') {
    @return get-color(100);
  }
  @if ($type == 'analysis' or $type == 'ANALYSIS') {
    @return get-color(200);
  }
  @if ($type == 'db' or $type == 'DB') {
    @return get-color(300);
  }
  @if ($type == 'etl' or $type == 'ETL') {
    @return get-color(400);
  }
  @if ($type == 'disable') {
    @return get-color(500);
  }

  @return get-color(50);
}

// old ui
@function get-color($palette_id, $opacity: 1) {
  @return mat-color($octopai-default, $palette_id, $opacity);
}

// new ui
@function get-octopai-color($palette_id, $opacity: 1) {
  @return mat-color($octopai, $palette_id, $opacity);
}

// old ui
@mixin svg-color($palette_id, $opacity: 1) {
  path {
    fill: get-color($palette_id, $opacity) !important;
  }

  path.a {
    fill: get-color($palette_id, $opacity) !important;
  }

  path.b {
    fill: get-color($palette_id, $opacity) !important;
  }

  ::ng-deep path {
    fill: get-color($palette_id, $opacity) !important;

    &.a {
      fill: get-color($palette_id, $opacity) !important;
    }

    &.b {
      fill: get-color($palette_id, $opacity) !important;
    }
  }
}

// new ui
@mixin svg-octopai-color($palette_id, $opacity: 1) {
  line {
    stroke: get-octopai-color($palette_id, $opacity) !important;
  }

  svg {
    fill: get-octopai-color($palette_id, $opacity) !important;
  }

  path {
    fill: get-octopai-color($palette_id, $opacity) !important;
  }

  path.fill {
    fill: get-octopai-color($palette_id, $opacity) !important;
  }

  .stroke {
    stroke: get-octopai-color($palette_id, $opacity) !important;
  }

  path.color {
    fill: get-octopai-color($palette_id, $opacity) !important;
  }

  .a {
    fill: get-octopai-color($palette_id, $opacity) !important;
  }

  .b {
    fill: get-octopai-color($palette_id, $opacity) !important;
  }

  .c {
    fill: get-octopai-color($palette_id, $opacity) !important;
  }

  ::ng-deep svg circle {
    fill: get-octopai-color($palette_id, $opacity) !important;
  }

  ::ng-deep svg g {
    fill: get-octopai-color($palette_id, $opacity) !important;
  }
}

@mixin color($palette_id, $opacity: 1) {
  fill: get-octopai-color($palette_id, $opacity) !important;
  //svg.fill {
  //  fill: get-octopai-color($palette_id, $opacity) !important;
  //}
}
