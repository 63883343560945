@import "styles/octopai-themes";


.select-drop-wrapper {
	padding: 5px 10px;
	border: 1px solid get-octopai-color(100);
	border-radius: 21px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: end;
	margin-right: 20px;

	&.advanced-search {
		width: 60% !important;
	}
}

.filter-tool {
	color: #365b89 !important;

	.mat-option.mat-option-disabled {
		color: rgba(0, 0, 0, 0.38) !important;
	}

	.mat-select-panel {
		height: 350px;
	}
	.mat-select-placeholder{
		color: #365b89 !important;
		font: normal normal normal 1.2rem/1.4rem Roboto;
		display: flex;
		justify-content: center;
	}


}

.mat-select-panel {
	position: absolute;
	top: 37px;
	left: 6px;
	min-width: calc(100% + 20px) !important;
	box-shadow: none !important;
	border: 2px solid get-color(500);
	background: get-color(1000);

	&.filter-tool {
		max-height: 500px;
		left: 30px !important;
	}

	.mat-select-content {
		padding: 0;
	}

	.mat-option {
		font-family: 'roboto-medium', Roboto, serif;
		width: 100%;
		height: 2rem !important;
		font-size: 1.1rem !important;
	}

	.mat-option.mat-active,
	.mat-option:hover:not(.mat-option-disabled),
	.mat-option:focus:not(.mat-option-disabled),
	.mat-option.mat-selected:not(.mat-option-multiple) {
		border-radius: 18px;
		background-color: get-octopai-color(450);
	}

	.mat-option-text {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&.abg-status-select {
		background-color: #fff;
		padding: 15px;
		border-radius: 15px;

		.mat-option {
			font-family: 'roboto-normal', Roboto, serif;
			color: get-octopai-color(100);
			width: 100%;
			height: 2rem !important;
		}
	}

	&.visualizer-select {
		top: 30px;
		border-radius: 15px;
		max-height: 500px;

		.mat-option {
			font-family: 'roboto-normal', Roboto, serif;
			color: #365b89;
			width: 100%;
			font-size: 13px !important;
		}

	}


}

[id^=list-form] {
	.mat-form-field-outline-start {
		border-radius: 28px 0 0 28px !important;
		border-left: 0.5px solid #CCD5DF;
		min-width: 28px !important;
		background: #E6EAEF80 0 0 no-repeat padding-box;
	}

	.mat-form-field-outline-end {
		border-radius: 0 28px 28px 0 !important;
		border-right: 0.5px solid #CCD5DF;
		background: #E6EAEF80 0 0 no-repeat padding-box;
	}

	.mat-form-field-flex > .mat-form-field-infix {
		padding: 0.5em 0 !important;
	}

	.mat-form-field-label-wrapper {
		top: -1.6em;
	}
}

.filterToolSelect {
	color: #365b89 !important;

	.mat-pseudo-checkbox {
		border: 1px solid rgba(0, 0, 0, 0.54) !important;
	}

	.mat-pseudo-checkbox-checked {
		background-color: white !important;
	}

	.mat-pseudo-checkbox::after {
		top: 3px;
		left: 2px;
		color: #365b89 !important;
	}
}
