mat-expansion-panel {

  mat-expansion-panel-header {
    &:not(.mat-expanded) {
      .mat-expansion-indicator {
        margin-top: 0 !important;
        margin-bottom: 7px !important;
      }
    }

    &.mat-expanded {
      .mat-expansion-indicator {
        margin-bottom: 0 !important;
        //margin-top: 7px !important;
      }
    }
  }


  &.adc-overview-tab {
    .mat-expansion-panel-body {
      max-height: 50vh !important;
      overflow-y: auto !important;
    }
  }
}
