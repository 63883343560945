angular-editor {
  direction: ltr;
  text-align: left;
  word-break: break-all;

  &.rtl {
    direction: rtl;
    text-align: right;
  }

  .angular-editor-textarea {
    color: #000;
    background-color: transparent;
    border: none !important;
    resize: none !important;
    min-height: 0 !important;
    padding: 0 !important;
    word-break: break-word;
  }

  &.edit {
    .angular-editor-textarea {
      background-color: #fff !important;
      min-height: 100px !important;
      padding: 0.5rem 0.8rem 1rem !important;
      word-break: break-word;
    }
  }
}
