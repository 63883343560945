:root {
	--side-bar-width: 341px;
	--view-div-collapsed-offset: -200px;
	--side-bar-width_header: 321px;
	--octopai: rgb(231, 80, 82);
	--scroller-back-color: silver;
	--green-btn: rgb(52, 191, 163);
	--green-btn-hover: rgb(44, 161, 137);
	--red-btn: rgb(243, 61, 91);
	--red-btn-hover: rgb(242, 45, 78);
	--etl-color: #36C5F0;
	--db-color: #E01E5A;
	--report-color: #2eb67d;
	--analysis-color: #ECB22E;
	--regular-link-color: rgb(81, 81, 81);
	--dependency-link-color: #6967ce;
	--white: #fff;
	--disable: #DFE1E5;
	--orange-bg: #e75052;
	--green-bg: #2eb67d;
	--yellow-bg: #ECB433;
	--red-bg: #DC3565;
	--blue-bg: #28BDBE;
}

$orange: #e75052;
$green: #2eb67d;
$yellow: #ECB433;
$red: #E01E5A;
$blue: #36C5F0;
$disable: rgba(223, 225, 229, 1);
