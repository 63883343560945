virtual-scroller {
	&.lineage-dashboard {
		width: 100%;
		box-sizing: border-box;
		margin-top: 5px;
	}

	&.add-links {
		height: 620px;
	}
}

.virtual-scrollable-inner {
	display: block;
	width: 100%;
	box-sizing: border-box;
}
