@import 'base/reset';
@import 'base/flex';
@import 'base/mark';
@import 'base/variables';

$round-button-font-size: 0.8rem;
$underline-button-font-size: 0.8rem;
$adc-filter-title-font-size: 1.1rem;
$adc-filter-sub-title-font-size: 1.1rem;
$adc-layer-settings-header-font-size: 1.4rem;
$text-form-control-font-size: 1.1rem;
$filter-width: 22rem;

.etl-color {
	color: var(--etl-color) !important;
}

.db-color, .database-color {
	color: var(--db-color) !important;
}

.report-color {
	color: var(--report-color) !important;
}

.analysis-color {
	color: var(--analysis-color) !important;
}

.context-menu {
  display: none;
  clear: both;
  width: 11%;
  position: absolute;
  z-index: 1000;

  ul {
    background: white;
		border: 1px solid #DFE1E5;
		border-bottom: 0;
		padding: 0;

		li {
			border-bottom: 1px solid #DFE1E5;
			padding: 5px 10px;
			list-style: none;
			cursor: pointer;

			.context-menu-icon {
				background-color: #F7F7F7;
				width: 24px;
				height: 24px;
				display: flex;
				justify-content: center;
				align-items: center;

				img {
					width: 16px;
					height: 16px;
				}

			}

			.context-menu-text {
				margin-left: 10px;
			}

			&:hover {
				background-color: #F7F3F0;
			}
		}
	}
}

.map-wrapper {
	position: relative;
	z-index: 0;
	height: 100%;

	&.loading {
		opacity: 0.1;
	}

	.overview-wrapper {
		position: absolute;
		right: 30px;
		top: 20px;
		z-index: 9999;

		.overview {
			width: 200px;
			height: 100px;
			border: 1px solid;
			background-color: white;
			z-index: 2;
		}
	}

	.viewDiv {
		height: calc(100vh - 144px);
		bottom: 0;
		right: 0;
		z-index: 1;
	}
}

.disabledMe {
	opacity: 0.3;
	cursor: default;
	pointer-events: none;
}

.rtl {
	direction: rtl !important;
	text-align: right !important;
}

.rtlView {
	direction: rtl !important;
}


/* HTML: <div class="loader"></div> */
.search-spinner {
	width: 30px;
	aspect-ratio: 1;
	border-radius: 50%;
	background: radial-gradient(farthest-side, #ffa516 94%, #0000) top/8px 8px no-repeat,
	conic-gradient(#0000 30%, #ffa516);
	-webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
	animation: l13 1s infinite linear;
	position: absolute;
	top: -1px;
	right: -10px;
}

@keyframes l13 {
	100% {
		transform: rotate(1turn)
	}
}


.text-direction-ltr {
	direction: ltr;
	text-align: justify;
}

.text-direction-rtl {
	direction: rtl;
	text-align: justify;
}
