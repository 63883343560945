@import "styles/octopai-themes";

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
	border-color: get-octopai-color(200) !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
	background-color: get-octopai-color(200) !important;
}

.mat-ripple-element {
	background-color: get-octopai-color(200) !important;
	opacity: 0.5;
}

.mat-radio-label {
	cursor: pointer;
	display: flex;
	align-items: center;
	white-space: nowrap;
	vertical-align: middle;
	width: 100%;
	user-select: text !important;
}

.mat-radio-label-content {
	overflow: hidden;
	text-overflow: ellipsis;
	padding-left: 8px;
	width: 100%;
}

.m-radio-container {
	align-items: center;
}
