.cdk-overlay-container {
	.dark-backdrop {
		opacity: 0.5;
	}

	.arrow-up {
		width: 0;
		height: 0;
		border-left: 14px solid transparent;
		border-right: 14px solid transparent;
		border-bottom: 10px solid #fede00;

		&.right {
			align-self: flex-end;
			border-bottom: 10px solid #feab00;
		}
	}

	.cdk-overlay-pane {
		&.custom-overlay-panel {
			position: absolute !important;
			top: 10px !important;
			display: flex;
			flex-direction: column;
		}

		.mat-autocomplete-panel {
			border-radius: 10px;

			&.compare {
			}

			&.tags {
				top: 2px;
			}

			&.discovery {
				max-height: 500px;

				mat-optgroup {
					&.title {
						label {
							text-align: left;
						}
					}

					label {
						background: none;
						height: 24px;
						line-height: 24px;
						padding: 0;
						color: #C7C9D5;
						font-size: 14px;
						margin: 14px 0px;
					}

					&.line-ETL label {
						color: var(--etl-color);
						border-bottom: 1px solid var(--etl-color);
					}

					&.line-DATABASE label {
						color: var(--db-color);
						border-bottom: 1px solid var(--db-color);
					}

					&.line-REPORT label {
						color: var(--report-color);
						border-bottom: 1px solid var(--report-color);
					}

					&.line-ANALYSIS label {
						color: var(--analysis-color);
						border-bottom: 1px solid var(--analysis-color);
					}
				}

				mat-option {
					height: 40px;
					line-height: 12px;
					font-size: 12px;
					padding: 5px;

					&.title {
						background: none;
						color: #7b7e8a;
						font-size: 13px;
						height: 35px;
					}

					&.no-result {
						span {
							text-align: center;
							display: flex;
							justify-content: flex-end;
							flex-direction: column;
						}
					}

					.type-item {
						font-size: 14px;
						color: #ADADB0;
					}

					.mat-option-text {
						height: 100%;

						.mat-option-row {
							height: 100%;
							align-items: flex-start;

							i {
								margin-right: 5px;
							}

							.text {
								height: 100%;
								justify-content: space-between;
							}
						}
					}

					.first-row {
						height: 20px !important;
						line-height: 0;
						margin-top: 8px;
						font-size: 1rem;
						color: #7b7e8a;

						&:hover {
							text-decoration: none;
						}
					}

					.second-row {
						color: rgb(87, 89, 98);
						font-size: 11px;
					}

					.oct-orange {
						color: #fc502f;
					}

					.etl-color {
						color: var(--etl-color);
						font-size: 0.9em !important;
					}

					.db-color {
						color: var(--db-color);
						font-size: 0.9em !important;
					}

					.report-color {
						color: var(--report-color);
						font-size: 0.9em !important;
					}

					.analysis-color {
						color: var(--analysis-color);
						font-size: 0.9em !important;
					}
				}
			}
		}
	}
}
