mat-checkbox {
  cursor: auto !important;

  .mat-checkbox-layout {
    cursor: pointer;
  }

  &.mat-checkbox-checked {
    .mat-checkbox-background {
      background-color: var(--orange-bg);
    }
  }

  &.mat-checkbox-checked.REPORT {
    .mat-checkbox-background {
      background-color: var(--green-bg);
    }
  }

  &.mat-checkbox-checked.ANALYSIS {
    .mat-checkbox-background {
      background-color: var(--yellow-bg);
    }
  }

  &.mat-checkbox-checked.DB {
    .mat-checkbox-background {
      background-color: var(--red-bg);
    }
  }

  &.mat-checkbox-checked.ETL {
    .mat-checkbox-background {
      background-color: var(--blue-bg);
    }
  }
}
