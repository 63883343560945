@import "octopai-themes";

.popover {
  background: get-octopai-color(500);
  border: 1px solid #DFE1E5;
  box-shadow: 0 0 12px #869db8;
  border-radius: 1rem;

  &.lineageDashboardPopover {
    left: 22px !important;
  }

  &.propertiesDataPopover {
    width: 100%;

    .popover-body {
      color: get-octopai-color(200);
      padding: 18px 22px;
      font-size: 1rem;
    }

    .arrow::before {
      border-bottom-color: get-octopai-color(500);
      border-top-color: get-octopai-color(500);
    }

    .arrow::after {
      border-bottom-color: get-octopai-color(500);
      border-top-color: get-octopai-color(500);
    }
  }

  .popOverClass {
    .minWidth {
      min-width: 350px;
    }
  }

  &.recent-activities-pop-over {
    background-color: get-octopai-color(500);
    width: 320px;
    max-width: 320px;
    min-width: 320px;

    .arrow::after {
      border-left-color: get-octopai-color(500);
      opacity: 0.5;
    }
  }
}

.tooltip-arrow-color {
  color: #ef6046 !important;
}

.topbar-popover {
	color: get-octopai-color(50);
	font-size: 1.4rem;
	background: get-octopai-color(500);
	display: flex;
	justify-content: space-between;
	font-family: "roboto-bold", Roboto, serif;
	align-items: center;
}
