@import 'styles/octopai-themes';

.mat-tab-body-wrapper {
//	//margin-top: 10px !important;
  flex: 1;
}

.mat-tab-label {
	.mat-tab-label-content {
		div.error {
			color: red !important;
		}
	}
}

//
//.tabs-wrapper {
//	.mat-tab-body-wrapper {
//		margin-top: 0 !important;
//	}
//
//	.mat-tab-header {
//		border-bottom: 1px solid rgba(0, 0, 0, 0.12)
//	}
//
//	.mat-tab-labels {
//		padding-top: 0.76rem;
//	}
//
//	.mat-tab-label {
//		opacity: 1;
//		height: 2.7rem !important;
//		border-top-left-radius: 10% 50%;
//		border-top-right-radius: 10% 50%;
//		margin: 0 0.1%;
//		background: get-octopai-color(500);
//		color: #000;
//		box-shadow: 0 -0.2rem 0.6rem #365B8924;
//		font-size: 1.1rem;
//
//		&.mat-tab-label-active {
//			background-color: get-octopai-color(200);
//			color: #000;
//		}
//	}
//
//	.mat-ink-bar {
//      background-color: transparent !important;
//	}
//}
