@import "../octopai-themes";

button {

  &.transparent-button {
    background-color: transparent;
    border: none;
    cursor: pointer;

    .text-button {
      color: get-octopai-color(200);
    }
  }

  &.mat-button-disabled {
    background-color: transparent !important;
    opacity: 0.3;
  }

  &.mat-flat-button {
    padding: 0;

    .mat-button-wrapper {
      padding: 0;
    }

    &.post-btn {
      .mat-button-wrapper {
        display: flex;
        align-items: center;
        height: 30px;
      }
    }

    .mat-badge-content {
      font-size: 10px !important;
    }
  }

  &.mat-raised-button {
    height: 35px;

    &:hover {
    }

    &.abg-filter {
      width: 150px;
    }

    &.transparent-button {
      color: get-color(50);
      box-shadow: none !important;

      &:hover {
      }

      &:disabled {
        color: get-color(A600) !important;
      }
    }
  }

  &.round-btn {
    color: get-octopai-color(500);
    width: 90%;
    height: 25px;
    border: none;
    font-size: $round-button-font-size;
    background: transparent linear-gradient(105deg, #DC3565 0%, #F16642 100%) 0 0 no-repeat padding-box;
    box-shadow: 0 3px 6px get-octopai-color(800);
    border-radius: 19px;
    text-align: center;
    vertical-align: middle;
    line-height: 4vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.underline-btn {
    color: get-octopai-color(600);
    font-size: $underline-button-font-size;
    text-align: left;
    text-decoration: underline;
    cursor: pointer;
    background-color: transparent;
    border: none;
  }


}

.mat-button-focus-overlay {
  background-color: transparent !important;
}
