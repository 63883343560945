@import 'styles/octopai-themes';

.mat-menu-panel {
  max-width: 2000px !important;
  border-radius: 15px;

  &.toolbar-menu {
    min-width: 52px;
    font-size: 13px;
    min-height: auto;

    .mat-menu-item {
      font-size: 13px !important;
      font-family: 'roboto-medium', Roboto, serif;
    }
  }

  &.user-selector, &.toolbar-menu {
    background: get-octopai-color(500);
    border-radius: 12px;
    padding: 0 !important;

    .mat-menu-content:not(:empty) {
      padding-bottom: 0px;
      padding-top: 0;

      .mat-menu-item {
        height: 35px;
        font-size: 15px;
        padding: 0 5px;
        color: #365B89;
      }

      .mat-menu-item:hover {
        background-color: get-octopai-color(250);
      }
    }
  }
}
