mat-form-field {
	width: 100%;

	&.post-form-field {
		.mat-form-field-wrapper {
			margin: 0;
			padding: 0;
		}
	}
}
