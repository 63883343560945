@import 'styles/octopai-themes';
@import 'styles/base';

mat-button-toggle-group.drawer-toggle {
  right: 0;
  position: absolute;
  z-index: 1000;
  background-color: get-octopai-color(100);
  top: 10px;

  mat-button-toggle {
    height: 24px;

    &.toggle-background {
      @include svg-octopai-color(500);
    }

    &.mat-button-toggle-checked {
      background-color: get-octopai-color(100);
    }

    button.mat-button-toggle-button {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      span.mat-button-toggle-label-content {
        line-height: 0;
        padding: 0;
        margin: 0;
        display: block;
      }
    }
  }
}

mat-drawer {
  .mat-drawer-inner-container {
    overflow: hidden;
  }
}
