@import 'styles/octopai-themes';

mark {
	color: get-octopai-color(600);
	font-weight: bold !important;
	padding: 0 !important;
	background-color: transparent;

	&.selected {
		background-color: var(--teal);
	}

	//&.breadcrumbs {
	//	padding: 0 5px !important;
	//	font-size: larger;
	//}

	b {
		color: inherit !important;
	}
}
