/* You can add global styles to this file, and also import other style files */
//@import "~@angular/material/prebuilt-themes/indigo-pink.css";

@import './styles/custom-theme';
@import '~ngx-bar-rating/themes/br-default-theme';
//@import '~codemirror/lib/codemirror';
//@import '~codemirror/theme/material';

@import "styles/base";
@import "styles/fontAwsome/style.css";
@import 'styles/code-mirror';
@import 'styles/compare';
@import 'styles/form-modal';
@import 'styles/m-widget';
@import 'styles/m-portlet';
@import 'styles/slider';
@import 'styles/popover';
@import 'styles/kendo';
@import 'styles/splitter';
@import "styles/ngx-daterangepicker";
@import 'styles/material';
@import 'styles/badge';
@import 'styles/mention-list';
@import 'styles/virtual_scroll.scss';
@import 'styles/material/drawer';
@import 'styles/material/button-toggle';
@import 'styles/material/matChip';
@import 'styles/input';
@import 'styles/skeleton';
@import 'styles/html-editor';

@font-face {
  font-family: roboto;
  font-weight: 400;
  src: local("roboto"), url(styles/fonts/roboto/Roboto-Regular.ttf) format("truetype");
}

@font-face {
  font-family: roboto-bold;
  font-weight: bold;
  src: local("roboto"), url(styles/fonts/roboto/Roboto-Bold.ttf) format("truetype");
}

@font-face {
  font-family: roboto-medium;
  font-weight: 500;
  src: local("roboto"), url(styles/fonts/roboto/Roboto-Medium.ttf) format("truetype");
}

@font-face {
  font-family: poppins;
  font-weight: 500;
  src: local("Poppins"), url(styles/fonts/Poppings/Poppins-Regular.ttf) format("truetype");
}

body, html {
  overflow: overlay;
  font-size: 14px;
  font-weight: 300;
}

.icon {
  cursor: pointer;
}

.mat-table__wrapper {
  width: 100%;
  overflow-x: auto;

  mat-cell, mat-header-cell {
    min-height: 100%;
  }
}

.mat-table__wrapper .mat-table {
  min-width: 1000px;
  width: 100%;
}

.mat-table__wrapper--dashboard .mat-table {
  min-width: 600px !important;
}


.mat-table__wrapper .mat-table .email-cell {
  word-wrap: normal;
  width: 180px;
  flex: auto;
}

.disabled-link {
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
}

.m-mat-dialog-container__wrapper .mat-dialog-container {
  padding: 0;
  overflow-x: hidden;

  .m-portlet {
    margin-bottom: 0;
  }

  .alert {
    border-radius: 0 !important;
    width: 1032px;
  }
}

.mat-table__wrapper--sub .mat-table {
  min-width: 400px;

  .mat-cell:last-child {
    flex: 0 0 100px;
  }

  .mat-header-cell:last-child {
    flex: 0 0 100px;
  }
}

.ml-auto {
  margin-left: auto;
}

.example-full-width {
  width: 100%;
}

mat-radio-button {
  padding-right: 16px;
}

.m-form--alert {
  margin-top: 30px;
}

.mat-column-checkbox {
  flex: 0 0 68px;
}

.mat-column-id {
  flex: 0 0 68px;
}

.mat-column-main {
  flex: 0 0 568px;
}

.mat-column-actions {
  flex: 0 0 160px;
}

.mat-table__bottom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.mat-column-vinCode {
  flex: 0 0 170px;
}

.m-portlet-mx-0 {
  margin: -1.1rem -2.2rem 0;
  overflow-x: hidden;
}

.mat-table__wrapper .mat-table .mat-row-editing.mat-row-inactive {
  height: 0;
  min-height: 0;
  padding: 0 !important;
  border-bottom: 0 !important;
}

/*lineage diagram circles colors*/
.stop-left-etl {
  stop-color: #26c6da;
}

.stop-right-etl {
  stop-color: #00acc1;
}

.stop-left-db {
  stop-color: #ec407a;
}

.stop-right-db {
  stop-color: #d81b60;
}

/*.stop-left-dbRight {
	stop-color: #f293b4;
}

.stop-right-dbRight {
	stop-color: #d16089;
}*/

.stop-left-dbSide {
  stop-color: #f27da5;
}

.stop-right-dbSide {
  stop-color: #d34e7f;
}

/*.stop-left-dbLeft {
	stop-color: #f27da5;
}

.stop-right-dbLeft {
	stop-color: #d34e7f;
}*/

.stop-left-analysis {
  stop-color: #ddc95a;
}

.stop-right-analysis {
  stop-color: #d8b80a;
}

.stop-left-report {
  stop-color: #66bb6a;
}

.stop-right-report {
  stop-color: #43a047;
}

.ngx-text-highlight {
  background: yellow;
}

.fade-in {
  opacity: 1;
  transition: opacity 2s linear;
}

.fade-out {
  opacity: 0;
  transition: opacity 2s linear;
}


//.CodeMirror {
//  font-family: monospace;
//  color: black;
//  direction: ltr;
//  position: relative;
//  //overflow: hidden;
//  //background: white;
//}

//
//.map {
//  .CodeMirror {
//    overflow-x: auto;
//  }
//}

m-spinner-button {
  &.margin-0 {
    button {
      margin: 0px !important;
    }
  }
}

mark > span {
  color: inherit;
}

.bold {
  font-weight: 600;
}

.m-form-section > .m-form-input, .m-form-input {
  margin-right: 5px !important;
  font-size: 1.1em !important;
  box-sizing: border-box;
  padding-left: 5px;
  margin-top: 8px;
  margin-bottom: 16px;
  width: 100%;
}

mat-form-field {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      flex-direction: row-reverse;
    }
  }
}

.alert-icon {
  color: #ff6358;
}

mat-checkbox.mat-checkbox.smaller-checkbox {
  .mat-checkbox-inner-container {
    width: 16px;
    height: 16px;
    margin-top: -2px;
  }
}

mat-card {
  height: auto;

  .mat-card-content {
    margin: 0;
    padding: 5px 0;

    .text-wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      font-size: 12px;
      overflow: auto;
      max-height: 150px;
    }
  }
}

.mention-mark {
  text-align: center;
  padding: 2px 10px;
  border: 1px solid #ededed;
  border-radius: 15px;
  margin: 3px;
  background-color: #DFE1E5;
  width: fit-content;
}
